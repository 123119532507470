import React from 'react';

import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import LargeButton from '../atoms/LargeButton';
import IconHeading from '../molecules/IconHeading';
import theme from '../../../styles/theme';

const PREFIX = 'AlertDialog';

const AlertDialog = (props) => {
  const handleClose = (event, reason) => {
    if (props.disableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (props.disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={props.isFullScreen}
    >
      <DialogTitle id="alert-dialog-title">
        <IconHeading
          title={props.title}
          icon={props.icon}
          className={props.className}
          className_2={props.className_2}
          style_2={props.style_2}
        />
      </DialogTitle>
      <DialogContent sx={{ minWidth: '420px' }}>
        <DialogContentText id="alert-dialog-description" sx={{ maxWidth: props.maxWidth || '420px' }}>
          {props.description}
        </DialogContentText>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ maxWidth: props.maxWidth || '420px', textAlign: props.alignText || 'center' }}
        >
          {props.description2}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ minWidth: '400px', padding: '24px' }}>
        <Grid container justifyContent="center" spacing={2}>
          {props.cancelLabel && (
            <Grid item xs={12} sm={props.confirmLabel ? 6 : 12}>
              <LargeButton buttonType="secondary" fullWidth noMargin onClick={props.onCancel} color="primary" autoFocus>
                {props.cancelLabel}
              </LargeButton>
            </Grid>
          )}
          {props.confirmLabel && (
            <Grid item xs={12} sm={props.cancelLabel ? 6 : 12}>
              <LargeButton
                fullWidth
                noMargin
                buttonType={props.buttonType}
                onClick={props.onConfirm}
                color={props.confirmButtonColor || theme.palette.background.blue}
                style={props.style}
              >
                {props.confirmLabel}
              </LargeButton>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool,
  isFullScreen: PropTypes.string,
  icon: PropTypes.object,
  isAuditExamPage: PropTypes.bool,
  confirmButtonColor: PropTypes.string,
};
export default AlertDialog;
