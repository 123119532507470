import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { getMostRecentHealthData } from '../../../actions/healthData';
import { Grid } from '@mui/material';
import HealthDataTable from './HealthDataTable';
import IconHeading from '../../../components/layout/molecules/IconHeading';
import SmallButton from '../../../components/layout/atoms/SmallButton';

import AddHealthData from '../AddHealthData';
import { handleModalOpen } from '../../../actions/controls';
import { ActivityIcon, PlusIcon } from '../../../resources/icons';

import { usePromiseTracker } from 'react-promise-tracker';
import Loading from '../../../components/layout/organisms/Loading/Loading';
import theme from '../../../styles/theme';

const PREFIX = 'HealthData';

const classes = {
  container: `${PREFIX}-container`,
  innerPadding: `${PREFIX}-innerPadding`,
};

const Root = styled('div')(({ theme }) => ({
  height: 420,
}));

const HealthData = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  return (
    <Root className={classes.container}>
      <div>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: theme.spacing(2) }}>
          <Grid item>
            <IconHeading title={'Latest Health Data'} icon={<ActivityIcon />} noMargin />
          </Grid>
          <Grid item>
            <SmallButton startIcon={<PlusIcon />} onClick={() => props.handleModalOpen('AddHealthData')}>
              {staticWords.New_Data}
            </SmallButton>
          </Grid>
        </Grid>
        {promiseInProgress ? (
          <Grid mt={12}>
            <Loading />
          </Grid>
        ) : (
          <div>
            {props.healthData && (
              <HealthDataTable healthData={Object.entries(props.healthData).reverse()} extraHeaderHeight />
            )}
          </div>
        )}
      </div>
      <AddHealthData btnName={staticWords.Submit} />
    </Root>
  );
};

HealthData.propTypes = {
  healthData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  healthData: state.healthData.mostRecentHealthData,
});

export default connect(mapStateToProps, {
  getMostRecentHealthData,
  handleModalOpen,
})(HealthData);
