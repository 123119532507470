import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { uiVisibleTexts } from './translation/visibleTexts';
import { de, enAU as en } from 'date-fns/locale';
import Cookies from 'js-cookie';
const resources = {
  en: {
    translation: { ...uiVisibleTexts.en },
  },
  de: {
    translation: { ...uiVisibleTexts.de },
  },
};
function getSupportedLanguage() {
  const language = window.navigator.language; // Get the browser's language
  if (language.startsWith('de')) {
    return 'de'; // German
  } else if (language.startsWith('en')) {
    return 'en'; // English
  } else {
    return 'en'; // Fallback to English
  }
}

const initialLang = getSupportedLanguage() || Cookies.get('django_language');

const availableLanguages = ['en', 'de'];
const options = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
  checkWhitelist: true,
};
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    lng: initialLang,
    resources,
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
      checkWhitelist: true,
    },
    whitelist: availableLanguages,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });
export const datePickerLangText = (argLang) => {
  switch (argLang) {
    case 'en': {
      return en;
    }
    case 'de': {
      return de;
    }
    default: {
      return en;
    }
  }
};
export default i18n;
