import {
  PAYMENT_SUCCESS,
  GET_PRICE_GROUPS,
  GET_CREDITS,
  SET_SNACKBAR_ERROR,
  GET_SERVICE_PRICES,
  GET_PAST_PURCHASES,
  GET_TRANSACTIONS,
} from './types';
import axiosInstance from '../axios';

export const getPriceGroups = () => (dispatch) => {
  axiosInstance
    .get('/payments/price_groups/list/')
    .then((result) => {
      dispatch({
        type: GET_PRICE_GROUPS,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          'There was a problem retrieving price groups. Please try again later.',
      });
    });
};

export const topUpCredits = (payment) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/payments/stripe/session/create/', payment)
      .then((result) => {
        dispatch({
          type: PAYMENT_SUCCESS,
          payload: result.data,
        });
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTotalCredits = () => (dispatch) => {
  axiosInstance
    .get('/payments/unit_credits/')
    .then((result) => {
      dispatch({
        type: GET_CREDITS,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          'There was a problem retrieving your credits data. Please refresh the page to try again.',
      });
    });
};

export const getServicePrices = () => (dispatch) => {
  axiosInstance
    .get('/payments/price/list/')
    .then((result) => {
      dispatch({
        type: GET_SERVICE_PRICES,
        payload: result.data,
      });
    })
    .catch((error) => {
      // dispatch({
      //   type: SET_SNACKBAR_ERROR,
      //   payload:
      //     error.response?.data?.errors?.[0]?.message ??
      //     'There was a problem retrieving the service prices. Please refresh the page to try again.',
      // });
    });
};

export const getPastPurchases = () => (dispatch) => {
  axiosInstance
    .get('/payments/unit_transaction/list/')
    .then((result) => {
      dispatch({
        type: GET_PAST_PURCHASES,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          'There was a problem retrieving your purchase history. Please refresh the page to try again.',
      });
    });
};

export const getTransactions = () => (dispatch) => {
  axiosInstance
    .get('/payments/unit_subscription/list/')
    .then((result) => {
      dispatch({
        type: GET_TRANSACTIONS,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          'There was a problem retrieving your payment history. Please refresh the page to try again.',
      });
    });
};
